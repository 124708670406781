.sidebar {
  position: absolute;
  right: -100%;
  width: 80%;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transition: right 0.2s;
  box-shadow: 0 0 10px 10px #0000006e;
}
.active {
  right: 0;
}

.sublink {
  margin-left: 15px;
}
