.active {
  color: black;
}

.link {
  cursor: pointer;
  transition: color 0.4s;
}
.link:hover {
  color: black;
}
.product_description {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: transparent;
  color: white !important;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  text-align: center;
  transition: background-color 0.3s, top 0.3s;

  align-items: center;

  h3 {
    color: white;
  }
  h1 {
    text-align: center;
    top: -40px;
    position: relative;
    opacity: 0;
    transition: top 0.3s;
  }
  h3 {
    font-size: 15px;
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.corner_bottomleft {
  position: absolute;
  z-index: 2;
  display: block;
  width: 0;
  height: 0;
  bottom: 30px;
  left: 30px;
  transition: width 0.3s, height 0.3s;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
}
.corner_topright {
  position: absolute;
  z-index: 2;
  display: block;
  width: 0;
  height: 0;
  top: 30px;
  right: 30px;
  transition: width 0.3s, height 0.3s;
  border-right: 2px solid white;
  border-top: 2px solid white;
}

.slide {
  aspect-ratio: 1;
  background-color: #efefef;
  cursor: pointer;
}
.product {
  position: relative;
  cursor: pointer;
  line-height: 0  ;
  display: inline-block;
  transition: box-shadow 0.5s, border-color 0.5s;
  box-shadow: 0 0 0px #e2e2ac;
  border: 1px solid #888;
  font-size: 0;
}

.product_description {
  line-height:15px;
}
.product:hover {
  box-shadow: 0 0 10px -5px black;
  .corner_bottomleft,
  .corner_topright {
    width: 30%;
    height: 30%;
  }
  .product_description {
    color: white;
    
    background-color: #000000c0;
    h1 {
      top: 0px;
    }
    h3 {
      opacity: 1;
    }
  }
}

.product_spotlight {
  display: inline-block;

  overflow: hidden;
  img {
    transition: height 0.3s;
  }
}

.description {
  line-height: 20px;
  .field {
    display: flex;
    align-items: baseline;
  }
  .label {
    width: 35%;
    display: inline-block;
    color: #888;
    text-transform: uppercase;
    font-family: 'Tundra';
    margin-right: 10px;
    font-size: 13px;
    line-height: 25px;
  }
  .value {
    display: inline-block;
    font-size: 13px;
    text-transform: uppercase;
    font-family: 'Tundra';
    color: black;

    text-align: left;
  }
}

.products_filter {
  span {
    margin: 5px;
  }
}

.product_container {
  display: flex;
  background: #efefef;
  flex-direction: row;

  margin: auto;
  flex: 1 1 0%;
  height: 1550px;
  overflow: hidden;
  width: 100%;
}

.thumbs_container {
  display: flex;

  flex-direction: column;
  height: 500px;

  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;

  max-width: 182px;
  .thumb {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 1px;
  }
  img {
    height: auto;
    width: 80px;
    transition: opacity 0.3s;
    display: inherit;
    display: block;
    opacity: 1;
    border: 1px solid #888;
  }

  .active_thumb {
    opacity: 0.4;
  }
}

.navigationButton {
  padding-top: 5px;
  flex: 1 0 0%;
  width: 200px;
  cursor: pointer;
  text-transform: uppercase;
  fill: #888;
  align-self: flex-end;
  opacity: 1;
  color: #888;
  transition: opacity 0.3s;
  z-index: 1;
  transition: color 0.3s, text-shadow 0.3s, fill 0.3s;
}

.navigationButton:hover {
  opacity: 1;
  color: black;
  fill: black;
}
.swiperClass {
  display: flex;
  flex-direction: row;
  .main {
    height: 450px;
    width: auto;
  }
}

@media screen and (max-width: 1024px) {
  .galleryWrapper {
    height: 100%;
  }
  .product_container {
    flex-direction: column;
    width: 100%;
  }
  .product_spotlight {
    width: 100%;
  }
  .thumbs_container {
    width: 100%;
    flex-direction: row;
    .thumb {
      margin-right: 10px;
      margin-top: 10px;
      margin-left: 0px;
      border: 1px solid #888;
    }

    img {
      height: 80px;
    }
  }
  .swiperClass {
    display: flex;
    flex-direction: column;

    .main {
      height: auto;
      width: 100%;
    }
  }
}

.paging_mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Tundra';
  z-index: 100;
  font-size: 16px;
  height: 40px;
  align-items: center;
}

.short_summary {
  width: 350px;
  display: inline-block;
  color: #333;
  display: flex;

  flex-direction: column;

  margin-right: 40px;
}

.desktopSpacer {
  margin-top: 40px;
}

@media screen and (min-width: 1024px) {
  .paging_mobile {
    display: none !important;
  }

  .navigationButton {
    align-items: 'flex-start';
  }
}
@media screen and (max-width: 1024px) {
  .description {
    line-height: 20px;

    .label {
      width: 30%;
    }
  }

  .short_summary {
    width: 100%;
  }
  .products_filter {
    display: none !important;
  }

  .product_container {
    flex-direction: column;
  }
  .product_spotlight {
    display: inline-block;
    width: 100%;
    min-width: unset;
    max-width: unset;
  }

  .desktopSpacer {
    margin-top: 0px;
  }
}

.product_detail {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  background: white;
  color: #888;
  z-index: 3;
  .detail {
    box-shadow: 0 0 10px black;
  }
  h2 {
    color: #aaa;
  }
}

.product_detail {
  h2:hover {
    color: black;
  }
}

.p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px;
}

.swiper {
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .p {
    flex-direction: row-reverse;
    margin: 40px 10%;
  }
  .swiperWrapper {
    width: 500px;
  }
  .swiper {
    height: 500px;
    position: relative;
    width: auto;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */

  margin-bottom: 15px;
}
