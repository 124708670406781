.regular {
  font-family: 'Arial';
  background: url('./images/misty.jpg') no-repeat center center fixed;
  background-size: cover;

  margin: 0;

  background-color: #060606;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;
  position: fixed;
}

.hidden {
  display: none;
}
.titletext {
  font-size: 20px;
  margin-top: 140px;
  line-height: 40px;
  letter-spacing: 1px;
  font-family: 'Tundra';
  text-align: center;
  text-shadow: 0px 0px 5px black;
  align-self: center;
  color: #fff;
  width: 100%;
  height: 160px;
  max-width: 600px;
  cursor: pointer;
  transition: transform 0.3s;

  img { width:100px !important;}
}
.titletext:hover {
  transform: scale(1.1);
}

.subtitle {
  font-family: 'Tundra';
  font-size: 15px;
  max-width: 400px;
  text-shadow: 0px 0px 5px black;
  display: flex;
  align-items: center;
  color: #aaa;
  cursor: pointer;
}
.subtitle:hover {
  color: #fff;
}
.interior_bg {
  width: 100%;
  height: 100%;
}
.bg {
  background-color: #efefef;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 14px;
  line-height: 14px;

  box-shadow: 0 0 10px black;
  position: absolute;
  padding: 30px 50px;
  overflow-y: scroll;
  z-index: 10;
  flex-wrap: wrap;
}
.activeLink {
  color: black;
}

.link {
  font-family: 'Tundra';
  margin-bottom: 20px;
  display: block;
}

.content {
  background-color: #efefef;
  width: 100%;
  min-height: 100%;
  font-size: 16px;
  box-shadow: 0 0 10px black;
  position: absolute;
  padding: 40px;

  z-index: 10;
}
.columns {
  padding: 0px;
  display: flex;
  flex-direction: row;
  margin: 0 5%;
  overflow-y: auto;

  flex-wrap: wrap;
}
.columns > span:first-child {
  flex: 1 1 0%;
  margin: 0px 0px;
}
.columns > span:nth-child(2) {
  margin-left: 80px;
  width: 300px;
  flex: 0 0 auto;
}
.main_card {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: white;
  overflow-y: scroll;
}
.footer {
  z-index: 100;
  flex: 0;
  text-align: center;
  color: #888;
  margin-bottom: 10px;
  font-size: 15px;
  .link {
    margin: 10px;
    transition: color 0.5s;
  }
  .active_link,
  .link:hover {
    cursor: pointer;
    color: white;
  }
}

.exploreButton {
  border-radius: 5px;
  font-size: 20px;
  text-transform: uppercase;
  color: #ccc;
  margin-right: 100px;
  margin-bottom: -30px;
  text-align: center;
  width: 200px;
  height: 200px;
  line-height: 15px;
  z-index: 100;
  cursor: pointer;
  flex-direction: column;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.exploreButton:hover {
  color: white;
  text-shadow: 0 0 5px white;

  .hand {
    background: white;
    transform: scale(1.1);
  }
}

.mobile {
  display: none !important;
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.wrapper {
  padding: 0;
  background-color: #efefef;
  width: 100%;
  height: 100%;
  min-height: 100%;

  line-height: 20px;
  text-align: justify;
  box-shadow: 0 0 10px black;
  position: absolute;

  overflow-y: scroll;
  z-index: 10;
}

.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: transparent;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.widescreen {
  display: none;
}

@media only screen and (min-width: 1350px) {
  .widescreen {
    display: block;
  }
  .columns {
    margin: 20px 15%;
  }
  .bg {
    padding: 30px 13%;
    // flex-direction: column;
  }
}

@media only screen and (max-width: 1024px) {
  .columns {
    flex-direction: column;
    margin: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .titletext {
    font-size: 15px;
    margin-top: 70px;
    line-height: 30px;
    letter-spacing: 1px;
    font-family: 'Tundra';
    text-align: center;
    text-shadow: 0px 0px 5px black;
    align-self: center;
    color: #fff;
    width: 80%;
    height: 160px;
    max-width: 600px;
  }

  .interior_bg {
    height: 100%;
    margin-left: -50%;
    width: auto;
  }

  .bg {
    padding: 0;
  }
  .wrapper {
    width: 100% !important;
    margin: auto;
  }

  .mainWindow {
    overflow: visible;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: inline-block !important;
  }
  .exploreButton {
    margin-right: auto;
    margin-bottom: auto;

    width: 100%;
    height: 100%;
  }
  .columns > span:first-child {
    //  min-width: 300px;
    width: 100%;
    flex: 1 1 auto;
    margin: 0px 0px;
  }

  .columns > span:nth-child(2) {
    margin-left: 0px;
    width: 100%;
    flex: 0 0 auto;
  }
}
.interior {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cloud {
  position: fixed;
  width: 200px;
  top: 100px;
  opacity: 0.2;
  animation: drop1 1000s linear infinite;
}
@keyframes drop1 {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
