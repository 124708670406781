.menu {
  z-index: 1;

  font-family: 'Tundra';
  display: flex;

  flex-direction: row;
  color: white;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), transparent);
  padding: 0 10%;
  padding-top: 20px;
  align-items: flex-start;

  .title_wrapper {
    flex-direction: column;

    align-items: start;

    width: auto;
    margin-left: 40px;
    margin-top: 60px;
  }

  .logo {
    display: inline-block;
    width: 150px;
    height: 50px;
    margin: 0px 40px;
    background-color: white;   
    mask-size: 100%;
  }

  .title {
    font-size: 44px;
    line-height: 50px;
    letter-spacing: 9px;
    text-align: left;
    position: relative;
    margin-left: 60px;
    z-index: 100;
    color: white;
    text-shadow: 1px 1px 9px black;
  }
  .subtitle {
    font-size: 20px;
    letter-spacing: 5px;
    color: white;
    opacity: 1;
    margin-top: -15px;
    padding-right: 14px;
    width: 100%;
    text-align: right;
    display: block;
    width: 400px;
    text-shadow: 1px 1px 9px black;
  }
  ul {
    padding: 0;

    margin: 0;
    margin-top: 20px;
    flex-direction: row;
    justify-content: center;
    display: flex;
    transition: width 0.5s;
    align-items: center;
    margin-right: 50px;
  }
  li {
    display: inline-block;
    padding: 0px;
    padding-bottom: 10px;
    margin: 0px;
    position: relative;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    color: #888;
    text-shadow: 1px 1px 4px transparent, 1px 1px 14px transparent;
    transition: color 0.3s, text-shadow 0.3s, border-bottom 0.3s;
    font-weight: bold;
    margin-right: 30px;
  }
  li:hover {
    color: white;
  }
  li.menu_active {
    color: white;
    text-shadow: 1px 1px 4px white, 1px 1px 14px white;
    border-bottom: 2px solid white;
  }
  .accent_top {
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 0px;
    top: 10px;
    left: 10px;
    transition: width 0.1s, height 0.1s;
  }
  .accent_bottom {
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 0px;
    bottom: 10px;
    right: 10px;
    transition: width 0.1s, height 0.1s;
  }
}
.active {
  position: absolute;
  height: 1px;
  top: 20px;
  background-color: white;
  transition: width 0.3s, left 0.3s;
}

li {
  border-bottom: 2px solid #ffffff00;
}

.menu_icon {
  display: none;
}

@media screen and (max-width: 1350px) {
  .menu {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 1024px) {
  .menu_icon {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  ul {
    margin-right: 20px;
  }

  .title {
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 4px !important;
    width: 100% !important;
    margin: 0 !important;
    margin-left: 20px !important;
  }
  .menu {
    color: white;
    padding-top: 10px;
    height: 70px;
    width: 100%;
    align-self: center;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .subtitle {
    display: none !important;
    width: 250px;
  }

  .mainPage .title {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  .mainPage .subtitle {
    display: block !important;
    width: 250px;
  }
}
