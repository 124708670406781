@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';

@font-face {
  font-family: 'Tundra';
  font-style: normal;
  src: url('./fonts/Tundra Regular.otf') format('truetype'); /* Safari, Android, iOS */
  src: url('./fonts/Tundra Bold.otf') format('truetype'); /* Safari, Android, iOS */
}

* {
  box-sizing: border-box;
}
body {
  font-family: 'Tundra';

  font-size: 15px;
  margin: 0;

  width: 100%;
  height: 100%;
  position: fixed;
}
.grecaptcha-badge {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Tundra';
}

button {
  text-transform: uppercase;
  line-height: 20px;
  font-size: 13px;
  letter-spacing: 2px;
  cursor: pointer;
  font-weight: bold;
  background-color: #697178;
  color: white;
  transition: background-color 0.5s;
}

button:hover {
  background-color: black;
}
h1 {
  font-size: 30px;
}
h4,
h5 {
  color: #aaa;
}

p {
  line-height: 22px;
  font-size: 14px;
  letter-spacing: 0.5px;
}
#root {
  height: 100%;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

img {
  transition: height 0.3s;
}

@media screen and (max-width: 1024px) {
  body {
    background-position-y: 0px;
  }
}

.swiper-button-next {
  color: white;
  transform: scale(1.2);
  margin-right: 20px;
  z-index: 100;
}

.swiper-button-prev {
  color: white;
  transform: scale(1.2);
  margin-left: 20px;
  z-index: 100;
}
.swiper-pagination {
  margin-bottom: 10px;
}

.swiper-pagination-bullet {
  background: #888;
  box-shadow: 0 0 5px 2px white;
  opacity: 1;
  z-index: 100;
}
.swiper-pagination-bullet-active {
  background: white;
  z-index: 100;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: #efefef;
  padding: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aaa;
  margin: 4px;
}

a {
  color: #888;
  text-decoration: none !important;
  transition: color 0.5s;
}
a:hover {
  color: #000 !important;
}
figure {
  margin:0;
}
.wp-block-group__inner-container {
  display:flex;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
 
 
  img {
    object-fit:contain !important;
    width:100% !important;
    height:unset !important;

  }
  iframe {
   
    width:100% !important;
    aspect-ratio: 16/9;

  }
}
