.contact {
  background-color: #efefef;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  box-shadow: 0 0 10px black;
  position: absolute;
  padding: 40px;
  overflow-y: scroll;
  z-index: 10;
}

h3 {
  color: #888;
}

.label {
  width: 100px;
  display: inline-block;
  font-family: 'Tundra';
}
.field {
  width: 100%;
  display: inline-block;
}

input {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 0;
  outline: none;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 0;
  border-radius: 0;
}

.button {
  background-color: none !important;
  width: 100% !important;
  border: 1px solid black !important;
  padding: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

::placeholder {
  font-family: 'Tundra';
}

@media only screen and (max-width: 1024px) {
  .button {
    width: 100% !important;
  }
}
